import React from 'react';
import { Base, Heading, Icon, Link, Paragraph, Strong } from "@brandwatch/axiom-components";
import SpringChain from '../../utils/SpringChain';

const contactUsLocation = "mailto:support@brandwatch.com";

const SignUpInvalid = () => {
  return (
      <div className="ax-status-message ax-text--align-center">
        <SpringChain>
         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Base className="ah-logo-page__logo">
              <Icon name={'brandwatch'} size="2.5rem" />
            </Base>
         </div>
        <Heading textSize="display1">
            <Strong>Ooops!</Strong>
          </Heading>
          <Heading textSize="headline">
          This link is no longer working, please contact support.
          </Heading>
          <Paragraph>
            Please contact{" "}
            <Link href={contactUsLocation}>support</Link>
          </Paragraph>
        </SpringChain>
      </div>
  );
};

export default SignUpInvalid;

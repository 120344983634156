import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { translate } from 'react-i18next';
import ChangePassword from '../ChangePassword';
import ExpiredPassword from '../ExpiredPassword';
import ForgotPassword from '../ForgotPassword';
import Login from '../Login';
import BackgroundPage from '../BackgroundPage/BackgroundPage';
import Notifications from '../Notifications';
import RateLimited from '../RateLimited';
import ResetPassword from '../ResetPassword';
import ResetPasswordExpired from '../ResetPasswordExpired';
import SignUpInvalid from '../SignUpInvalid';
import SignUpExpired from '../SignUpExpired';
import SignUpConfirmation from '../SignUpConfirmation';
import TwoFactor from '../TwoFactor';
import TwoFactorSetup from '../TwoFactorSetup';

import './App.css';

export class App extends Component {
  static contextTypes = {
    i18n: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    axiomLanguage: PropTypes.string.isRequired,
  };

  getChildContext() {
    return {
      axiomLanguage: this.context.i18n.language || 'en',
    };
  }

  render() {
    return [
      <Notifications key="notifications" />,
      <BackgroundPage key="background">
        <Switch>
          <Route component={ Login } exact path="/" />

          <Route component={ ChangePassword } exact path="/change" />
          <Route component={ ExpiredPassword } exact path="/expired" />
          <Route component={ ForgotPassword } exact path="/forgot" />
          <Route component={ Login } exact path="/login" />
          <Route component={ RateLimited } exact path="/restricted" />
          <Route component={ ResetPassword } exact path="/reset" />
          <Route component={ ResetPasswordExpired } exact path="/reset/expired" />
          <Route component={ SignUpInvalid } exact path="/sign-up" />
          <Route component={ SignUpExpired } exact path="/sign-up/expired" />
          <Route component={ SignUpConfirmation } exact path="/sign-up/confirmation" />
          <Route component={ TwoFactor } exact path="/multi-factor" />
          <Route component={ TwoFactorSetup } exact path="/multi-factor/setup" />

          <Route render={ () => (
            <Redirect to="/404" />
          ) } />
        </Switch>
      </BackgroundPage>,
    ];
  }
}

export default translate()(App);
